import React from "react";
import {Link} from "react-router-dom";

const Products = ({products})=>{

        return  (
            products.map((product)=>(
                <div key={'p'+product.idproducto} className="dish-block" >
                    <div className="inner-box">
                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{(product.nombreProducto).toLowerCase()} <span className="s-info">...</span></Link></h5></div><div className="price"><span>${new Intl.NumberFormat().format(product.precio)}</span></div></div>
                        <div className="text desc"><Link to="#">{product.descripcionProducto}</Link></div>
                    </div>
                </div>
            ))
                )

}
export default Products