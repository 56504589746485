import {BrowserRouter as Router} from "react-router-dom"
import Routing from './routes';
import './assets/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/responsive.css'

function App() {

  return (
      <Router>
        <Routing />
      </Router>
  );
}

export default App;

