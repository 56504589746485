import React from "react";
import bgone from "../../../assets/images/background/tenedor.png";
import Products from "../../Product/ListProducts/Products";
import {URL_PUBLIC} from "../../../backend";


const Categories = ({categories})=>{


     return (
                categories.map((category,index)=>(

                    <section key={"category"+category.idcategoria} className={index%2===0?("menu-one alternate"):"menu-one"}>
                        <div className={index%2===0?("right-bg"):"left-bg"}><img src={bgone} alt="" title="" width={100}/></div>
                        <div className="auto-container">
                            <div className="title-box centered">
                                <div className="subtitle"><span>CATEGORÍA</span></div>

                                <div className="pattern-image">
                                    <img src={require('../../../assets/images/icons/separator.svg').default}
                                         alt='mySvgImage'/>
                                </div>

                                <h2 id={"category"+category.idcategoria}>{category.nombreCategoria}</h2>
                            </div>

                            <div className="row clearfix">
                                <div className="image-col col-lg-7 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="image"><img src={URL_PUBLIC+"/imagenes/restaurant"+category.restaurant.idrestaurant+"/categorias/categoria"+category.idcategoria+".jpg"} alt=""/></div>
                                    </div>
                                </div>
                                <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                                    <div className="inner">

                                        <Products products={category.productos}></Products>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ))
            )
}
export default Categories