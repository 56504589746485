import React, {  useState, useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom';
import Main from '../components/Home/Main'
import Header from '../components/Header/Main'
import Footer from '../components/Footer/Main'
import Banner from "../components/Banner/Main";
import axios from "axios";
import {BACKEND} from "../backend";
import BarLoader from 'react-spinners/BarLoader';

function Index() {

    const [restaurant, setRestaurant] = useState(false);
    const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [loading,setLoading]=useState(false);
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


    useEffect(() => {
        setLoading(true);
        if (location.pathname === "/hometwo"){
            sethomepage(false)
        }else{
            sethomepage(true)
        }
    }, [location])

    // const location = useLocation()
    const path = location.pathname
    useEffect(() => {
        window.scroll(0, 0)
    }, [path]);

    async function getRestaurant(){
        let url= BACKEND+'/getRestaurant?idrestaurant='+params.get('id');
        console.log(url);
        const response = await axios.get(url);
        setLoading(false);
        setRestaurant(response.data.restaurant);

    }
    useEffect(()=>{
        getRestaurant();
    },[])


    return (


        <>
            <div style={style}>
            {

                loading && <BarLoader size={150} speedMultiplier={0.5} color={"#cacaca"} ></BarLoader>

            }
            </div>
            {restaurant &&
                <Header restaurant={restaurant}></Header>
            }
            {restaurant && homepage &&
                <Header restaurant={restaurant}></Header> &&
                <Banner restaurant={restaurant}></Banner>
            }

            <Routes>
                <Route path="/" element={<Main id={params.get('id')}/>}/>
            </Routes>

            {
                restaurant && <Footer restaurant={restaurant}></Footer>
            }

        </>

    )
}

export default Index