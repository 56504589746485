import React, {useEffect, useState} from 'react'
import Categories from "./ListCategories/Categories";
import axios from "axios";
import {BACKEND} from "../../backend";


const Category = ({idrest})=>{

        const [categories, setCategories] = useState([]);
        async function getCategoriesProducts() {
                let url = BACKEND+ '/getCategoriesProducts?idrestaurant='+idrest.id;
                console.log(url);
                const response = await axios.get(url);
                setCategories(response.data.categories);
        }

        useEffect(()=>{
           getCategoriesProducts();
        },[]);


        return  (
            <Categories categories={categories} />
        )


}
export default Category