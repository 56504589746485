import React from 'react';
import Slidetwo from '../../assets/images/main-slider/slider-2.jpg'
import { Link } from 'react-router-dom'
import {URL_PUBLIC} from "../../backend";


const Footer = ({restaurant})=> {
    return (
        <>

            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Slidetwo})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">

                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Delici - Restaurants HTML Template"><img src={URL_PUBLIC+"/imagenes/restaurant"+restaurant.idrestaurant+"/logo/logo.png"} alt="" title="Delici - Restaurants HTML Template" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Restaurant, {restaurant.nombre}, {restaurant.direccion}, CL</li>
                                                <li><Link to={"mailto:"+restaurant.nombre}>{restaurant.mail}</Link></li>
                                                <li><Link to="tel:+88-123-123456">Teléfono/s : {restaurant.telefono}</Link></li>
                                                <li>Horario: {restaurant.horario}</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        {/*
                                        <div className="newsletter">
                                            <h3>Ofertas</h3>
                                            <div className="text">Ingresa tu correo y obtén un <span>10% de descuento.</span></div>
                                            <div className="newsletter-form">
                                                <form method="post" action="index.html">
                                                    <div className="form-group">
                                                        <span className="alt-icon far fa-envelope"></span>
                                                        <input type="email" name="email" placeholder="Mail" required />
                                                        <button type="submit" className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">suscríbete</span>
                                                                <span className="text-two">suscríbete</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <a href="https://www.facebook.com">facebook</a></li>
                                        <li> <a href="https://www.instagram.com">instagram</a></li>
                                        <li> <a href="https://twitter.com">Twitter</a></li>
                                        <li> <a href="https://www.youtube.com">Youtube</a></li>
                                        <li> <a href="https://www.google.com/maps">Google map</a></li>
                                    </ul>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2022 SoftGO by BIOT SpA. All Rights Reserved   |    Creado por <Link to="https://themeforest.net/user/kalanidhithemes" target="blank">Kalanidhi plantillas</Link></div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>

        </>

    )
}

export default Footer