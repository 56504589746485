import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import bookicon1 from '../../assets/images/background/pedido.png'
import {URL_PUBLIC} from "../../backend";


const Banner = ({restaurant})=> {
    return (
        <>

            <Swiper className="banner-section"
                    loop={true}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.swiper-button-prev',
                        prevEl: '.swiper-button-next',
                    }}>

                <div className="banner-container">
                    <div className="banner-slider" >
                        <div className="swiper-wrapper">

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">

                                    <div className="image-layer" style={{backgroundImage: 'url('+URL_PUBLIC+"/imagenes/restaurant"+restaurant.idrestaurant+"/logo/slider-1.jpg"+')'}}></div>

                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>experiencia encantadora</span></div>

                                                        <div className="pattern-image">

                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                                                        </div>


                                                        <h1><span><br />{restaurant.nombre}</span></h1>
                                                        <div className="text">Carta Online</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{backgroundImage: 'url('+URL_PUBLIC+"/imagenes/restaurant"+restaurant.idrestaurant+"/logo/slider-2.jpg"+')'}}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span><br />{restaurant.nombre}</span></h1>
                                                        <div className="text">Carta Online</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{backgroundImage: 'url('+URL_PUBLIC+"/imagenes/restaurant"+restaurant.idrestaurant+"/logo/slider-3.jpg"+')'}}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span><br />{restaurant.nombre}</span></h1>
                                                        <div className="text">Carta Online</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </div>

                        <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                        <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>


                    </div>
                </div>

                <div className="book-btn">
                    <Link to="#" className="theme-btn">
                        <span className="icon">
                            <img src={bookicon1} alt="" title="" />
                        </span>
                        <span className="txt">book a table</span>
                    </Link>
                </div>

            </Swiper>

        </>
    )
}

export default Banner