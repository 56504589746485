import React from 'react'
import Category from '../Category/Main'


function Main(id) {

    return (

        <>

            <Category idrest={id}></Category>

        </>

    )
}

export default Main