import React, { useState, useEffect } from 'react'
import {Link, useLocation} from 'react-router-dom'
import axios from "axios";
import {BACKEND, URL_PUBLIC} from "../../backend";


const Header = ({restaurant})=> {

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [categories,setCategories]=useState([]);
    const [active, setActive] = useState();
    const [show, setShow] = useState();
    const [menu, setMenu] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);

    const handleScroll=() => {

        const offset=window.scrollY;
        setlastscrool(offset);

        if(offset > 1000 && offset < lastscrool){
            setNav(true);
            setScrolled(true);
        }

        else if(offset > 1000 ){
            setNav(false)
        }

        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }

        else{
            setScrolled(false);
            setNav(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',handleScroll);
    });

    async function getCategories(){
        let url= BACKEND+ '/getCategories?idrestaurant='+params.get('id');
        const response = await axios.get(url);
        setCategories(response.data.categories);
        console.log(response.data.categories);
    }

    useEffect(()=>{
        getCategories();
    },[]);

    function setVisibleBar(value){
        document.getElementById()
    }

    return (

        <>

            {categories && active &&
                <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }

            <section className={`hidden-bar ${active && "visible-sidebar"}`}  >
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
                    <div className="logo-box"><Link to={"/?id="+restaurant.uniqueid} title={restaurant.nombre}><img src={URL_PUBLIC+"/imagenes/restaurant"+restaurant.idrestaurant+"/logo/logo.png"} alt="" title={restaurant.nombre} />
                    </Link></div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li className="current"><Link to={"/?id="+restaurant.uniqueid} onClick={() => setShow(show === true ? false : true)}>Home</Link>

                            </li>
                            <li className="dropdown"><Link to="#" onClick={() => setMenu(menu === true ? false : true)} >Categorías<button type="button" className="btn-expander"><i className="far fa-angle-down"></i></button></Link>
                                <ul style={{ display: menu ? "block" : "none" }}>
                                    {
                                        categories.map((category)=>(
                                        <li key={"category_"+category.idcategoria} onClick={()=>setActive(false)}><a href={"#category"+category.idcategoria}>{category.nombreCategoria}</a></li>
                                        ))
                                    }
                                </ul>
                            </li>
                            <li className="current"><Link to={URL_PUBLIC} onClick={() => setShow(show === true ? false : true)}>Login usuario</Link>

                            </li>
                            <li className=""><Link to='http://soft-go.cl/registroCliente?cartaqr=1' onClick={() => setShow(show === true ? false : true)}>Comprar carta*</Link>

                            </li>
                        </ul>
                    </div>

                    <h2>Visítanos</h2>
                    <ul className="info">
                        <li>Restaurant, {restaurant.nombre}, <br /> {restaurant.direccion}, CL </li>
                        <li>Horario: {restaurant.horario}</li>
                        <li><Link to={"mailto:"+restaurant.mail}>{restaurant.mail}</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Contacto</div>
                        <div className="bk-no"><Link to={"tel:"+restaurant.telefono}>{restaurant.telefono}</Link></div>
                    </div>

                </div>
            </section>
            {Nav &&
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> Restaurant, {restaurant.nombre}, {restaurant.direccion}, CL</li>
                                        <li><i className="icon far fa-clock"></i> Horario: {restaurant.horario}</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li><Link to={"tel:"+restaurant.nombre}><i className="icon far fa-phone"></i> {restaurant.telefono} </Link> </li>
                                        <li><Link to={"mailto:"+restaurant.mail}><i className="icon far fa-envelope"></i> {restaurant.mail}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="logo-box">
                                    <div className="logo"><Link to="/" className="font-weight-bold" title={"Restaurant, "+restaurant.nombre}><img src={URL_PUBLIC+"/imagenes/restaurant"+restaurant.idrestaurant+"/logo/logo.png"} alt="" title={"Restaurant, "+restaurant.nombre} />{restaurant.nombre}</Link></div>
                                </div>



                                <div className="link link-btn">
                                    <Link to="#" className="theme-btn btn-style-one clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">{restaurant.telefono}</span>
                                            <span className="text-two">{restaurant.telefono}</span>
                                        </span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </header> }

        </>

    )
}

export default Header